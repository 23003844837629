<template>
  <div class="backgroundImg">
    <v-main>
      <v-container>
        <loader v-if="showLoader" />
        <v-col
          v-else-if="order.status == 'Payed'"
          class="px-0 py-0"
          style="text-align: center"
        >
          <img
            src="@/assets/img/successPaymentIcon.svg"
            :width="$vuetify.breakpoint.smAndDown ? '150px' : '240px'"
            :height="$vuetify.breakpoint.smAndDown ? '150px' : '240px'"
            alt="success payment"
          />
          <h3
            style="
              color: #149e51;
              text-align: center;
              font-family: MacPaw Fixel Display;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            "
            :style="
              $vuetify.breakpoint.smAndDown
                ? ' font-size: 32px;'
                : ' font-size: 24px;'
            "
          >
            Дякуємо за довіру
          </h3>
          <p
            style="margin-top: 12px; margin-bottom: 8px"
            :style="!$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : ''"
            class="text"
          >
            Оплата пройшла успішно!
          </p>
          <p
            class="text"
            :style="!$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : ''"
          >
            Щоб отримати квиток відскануйте QR-code або завантажте.
          </p>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            v-if="$vuetify.breakpoint.smAndDown"
          >
            <submit-outline-button
              style="width: 100%; height: 48px !important"
              @click="downloadQrToPDF"
              :loading="loading"
              :style="
                isDownload
                  ? 'background: #085895; color: #EEEEEE; pointer-events:none;'
                  : ''
              "
              >{{
                isDownload ? "Завантажено" : "Завантажити PDF квиток"
              }}</submit-outline-button
            >
            <submit-button
              style="width: 100%; height: 48px !important; margin-top: 24px"
              @click="$router.push('/')"
            >
              На головну
            </submit-button>
          </v-col>
          <v-row
            no-gutters
            style="margin: 24px 0px"
            class="px-0 py-0"
            align="center"
            v-if="$vuetify.breakpoint.smAndDown"
          >
            <div
              style="
                background: rgba(185, 185, 185, 0.6);
                width: calc(50% - 22px);
                height: 1px;
                margin-top: 4px;
              "
            />
            <div style="width: 24px; margin: 0px 10px">
              <span
                style="
                  color: #1b1b1b;
                  font-family: MacPaw Fixel Display;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  letter-spacing: 1.2px;
                "
                >або</span
              >
            </div>

            <div
              style="
                background: rgba(185, 185, 185, 0.6);
                width: calc(50% - 22px);
                height: 1px;
                margin-top: 4px;
              "
            />
          </v-row>
          <v-row
            no-gutters
            justify="center"
            align="center"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-top: 24px'
                : 'margin-top: 56px'
            "
          >
            <div
              :style="
                $vuetify.breakpoint.smAndDown
                  ? 'width: max-content; height: 240px'
                  : 'width: 310px; height: 310px'
              "
            >
              <VueQRCodeComponent
                :text="text"
                :size="$vuetify.breakpoint.smAndDown ? 240 : 310"
                color="black"
                render-as="svg"
              />
            </div>
            <div
              :style="
                $vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 25px'
              "
            >
              <v-col
                style="margin: 0px 24px"
                class="px-0 py-0"
                v-if="!$vuetify.breakpoint.smAndDown"
              >
                <div
                  style="
                    background: rgba(185, 185, 185, 0.6);
                    width: 1px;
                    height: 140px;
                    margin-left: 10px;
                  "
                />
                <span
                  style="
                    color: #1b1b1b;
                    font-family: MacPaw Fixel Display;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 1.2px;
                  "
                  >або</span
                >
                <div
                  style="
                    background: rgba(185, 185, 185, 0.6);
                    width: 1px;
                    height: 140px;
                    margin-left: 10px;
                  "
                />
              </v-col>
            </div>
            <v-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
              <v-row no-gutters justify="center">
                <submit-outline-button
                  style="width: 316px; height: 48px !important"
                  @click="downloadQrToPDF"
                  :loading="loading"
                  :style="
                    isDownload
                      ? 'background: #085895; color: #EEEEEE; pointer-events:none;'
                      : ''
                  "
                  >{{
                    isDownload ? "Завантажено" : "Завантажити PDF квиток"
                  }}</submit-outline-button
                >
                <submit-button
                  style="
                    width: 316px;
                    height: 48px !important;
                    margin-top: 24px;
                  "
                  @click="$router.push('/')"
                >
                  На головну
                </submit-button>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else style="text-align: center">
          <img
            src="@/assets/img/errorIcon.png"
            :width="$vuetify.breakpoint.smAndDown ? '150px' : '240px'"
            :height="$vuetify.breakpoint.smAndDown ? '150px' : '240px'"
            alt="success payment"
          />
          <h3
            style="
              color: #ed4337;
              text-align: center;
              font-family: MacPaw Fixel Display;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              margin-top: 32px;
            "
            :style="
              $vuetify.breakpoint.smAndDown
                ? ' font-size: 32px;'
                : ' font-size: 24px;'
            "
          >
            Помилка оплати
          </h3>
          <p
            class="text"
            style="margin-top: 12px"
            :style="!$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : ''"
          >
            Схоже, щось пішло не так. Квиток не був оплачений або виникла
            помилка.
          </p>
          <v-row no-gutters justify="center">
            <submit-button
              style="
                width: 300px;
                height: 48px !important;
                font-weight: 500;
                font-size: 18px;
              "
              color="#144FA9"
              @click="$router.push('/')"
            >
              На головну
            </submit-button>
          </v-row>
        </v-col>
      </v-container>
    </v-main>
    <vue-html2pdf
      v-if="order !== null"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      :filename="`Квиток ${order?.departure?.translations?.name} - ${
        order?.destination?.translations?.name
      } ${new Date(order?.schedule?.departed_at).toLocaleDateString('uk-UA', {
        month: 'short',
        day: 'numeric',
      })}`"
      pdf-format="a4"
      :pdf-margin="100"
      pdf-orientation="portrait"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <pdf-ticket
          :ticket="order"
          :isPdf="isPdf"
          :text="text"
          :isFront="true"
          :indexTicket="indexTicket"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>
<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=AW-11453708969"></script>

<script>
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag("js", new Date());
gtag("config", "AW-11453708969");
</script> -->
<script>
import VueQRCodeComponent from "vue-qrcode-component";
import VueHtml2pdf from "vue-html2pdf";
import diffrentTimeMixin from "../../mixins/diffrentTimeMixin";
import Loader from "../../components/UI/Loader.vue";
import pdfTicket from "../../components/User/pdfTicket.vue";
import bookingTicketService from "../../requests/User/bookingTicketService";
import SubmitButton from "../../components/UI/Buttons/submitButton.vue";
import SubmitOutlineButton from "../../components/UI/Buttons/submitOutlineButton.vue";
export default {
  mixins: [diffrentTimeMixin],
  components: {
    VueQRCodeComponent,
    VueHtml2pdf,
    pdfTicket,
    Loader,
    SubmitButton,
    SubmitOutlineButton,
  },
  data: () => ({
    text: "https://dev.mbus.if.ua/order_qr/018c401e-a465-72b5-bf3f-082b392972a0",
    order: {},
    isPdf: true,
    indexTicket: 0,
    loading: false,
    showLoader: true,
    isDownload: false,
  }),
  mounted() {
    // gtag("event", "conversion", {
    //   send_to: "AW-11453708969/BR3hCMjAroMZEKn1xtUq",
    //   transaction_id: this.$route.params.order_id,
    // });
    this.getOrderById();
  },
  methods: {
    async getOrderById() {
      await bookingTicketService
        .getOrderByQr(this.$route.params.order_id)
        .then((res) => {
          //if (res.data.status == "Payed") {
          this.order = res.data;
          this.text = `${location.protocol}//${location.host}/order_qr/${this.order.uuid}`;
          this.showLoader = false;
          // } else {
          //   this.$router.push("/");
          // }
        });
    },
    async downloadQrToPDF() {
      this.loading = true;
      setTimeout(async () => {
        for (const [index] of this.order.tickets.entries()) {
          this.indexTicket = index;
          this.isPdf = true;
          this.isDownloadTicket = true;
          await this.$refs.html2Pdf.generatePdf();
          await new Promise((resolve) => setTimeout(resolve, 500));
          this.downloadPDF = true;
        }
      }, 500);
      setTimeout(() => {
        this.loading = false;
        this.isDownload = true;
      }, 500);
    },
  },
};
</script>

<style scoped>
.backgroundImg {
  background-image: url("../../assets/img/mapBackground.png");
  padding: 50px 0px 150px 0px;
}
.text {
  color: #1b1b1b;
  text-align: center;
  font-family: MacPaw Fixel Display;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.downloadBtn {
  border-radius: 5px;
  border: 2px solid #085895;
  text-transform: none;
  background: #fcfcfc;
  color: #1b1b1b;
  text-align: center;
  font-family: MacPaw Fixel Display;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.downloadBtn:hover {
  text-decoration: underline;
  color: #085895;
}
</style>