var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"backgroundImg"},[_c('v-main',[_c('v-container',[(_vm.showLoader)?_c('loader'):(_vm.order.status == 'Payed')?_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/img/successPaymentIcon.svg"),"width":_vm.$vuetify.breakpoint.smAndDown ? '150px' : '240px',"height":_vm.$vuetify.breakpoint.smAndDown ? '150px' : '240px',"alt":"success payment"}}),_c('h3',{staticStyle:{"color":"#149e51","text-align":"center","font-family":"MacPaw Fixel Display","font-style":"normal","font-weight":"500","line-height":"normal"},style:(_vm.$vuetify.breakpoint.smAndDown
              ? ' font-size: 32px;'
              : ' font-size: 24px;')},[_vm._v(" Дякуємо за довіру ")]),_c('p',{staticClass:"text",staticStyle:{"margin-top":"12px","margin-bottom":"8px"},style:(!_vm.$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : '')},[_vm._v(" Оплата пройшла успішно! ")]),_c('p',{staticClass:"text",style:(!_vm.$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : '')},[_vm._v(" Щоб отримати квиток відскануйте QR-code або завантажте. ")]),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('submit-outline-button',{staticStyle:{"width":"100%","height":"48px !important"},style:(_vm.isDownload
                ? 'background: #085895; color: #EEEEEE; pointer-events:none;'
                : ''),attrs:{"loading":_vm.loading},on:{"click":_vm.downloadQrToPDF}},[_vm._v(_vm._s(_vm.isDownload ? "Завантажено" : "Завантажити PDF квиток"))]),_c('submit-button',{staticStyle:{"width":"100%","height":"48px !important","margin-top":"24px"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" На головну ")])],1):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('v-row',{staticClass:"px-0 py-0",staticStyle:{"margin":"24px 0px"},attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticStyle:{"background":"rgba(185, 185, 185, 0.6)","width":"calc(50% - 22px)","height":"1px","margin-top":"4px"}}),_c('div',{staticStyle:{"width":"24px","margin":"0px 10px"}},[_c('span',{staticStyle:{"color":"#1b1b1b","font-family":"MacPaw Fixel Display","font-size":"12px","font-style":"normal","font-weight":"400","line-height":"normal","letter-spacing":"1.2px"}},[_vm._v("або")])]),_c('div',{staticStyle:{"background":"rgba(185, 185, 185, 0.6)","width":"calc(50% - 22px)","height":"1px","margin-top":"4px"}})]):_vm._e(),_c('v-row',{style:(_vm.$vuetify.breakpoint.smAndDown
              ? 'margin-top: 24px'
              : 'margin-top: 56px'),attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('div',{style:(_vm.$vuetify.breakpoint.smAndDown
                ? 'width: max-content; height: 240px'
                : 'width: 310px; height: 310px')},[_c('VueQRCodeComponent',{attrs:{"text":_vm.text,"size":_vm.$vuetify.breakpoint.smAndDown ? 240 : 310,"color":"black","render-as":"svg"}})],1),_c('div',{style:(_vm.$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 25px')},[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{staticClass:"px-0 py-0",staticStyle:{"margin":"0px 24px"}},[_c('div',{staticStyle:{"background":"rgba(185, 185, 185, 0.6)","width":"1px","height":"140px","margin-left":"10px"}}),_c('span',{staticStyle:{"color":"#1b1b1b","font-family":"MacPaw Fixel Display","font-size":"12px","font-style":"normal","font-weight":"400","line-height":"normal","letter-spacing":"1.2px"}},[_vm._v("або")]),_c('div',{staticStyle:{"background":"rgba(185, 185, 185, 0.6)","width":"1px","height":"140px","margin-left":"10px"}})]):_vm._e()],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-col',{attrs:{"cols":"12","xl":"4","lg":"4","md":"4","sm":"12"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('submit-outline-button',{staticStyle:{"width":"316px","height":"48px !important"},style:(_vm.isDownload
                    ? 'background: #085895; color: #EEEEEE; pointer-events:none;'
                    : ''),attrs:{"loading":_vm.loading},on:{"click":_vm.downloadQrToPDF}},[_vm._v(_vm._s(_vm.isDownload ? "Завантажено" : "Завантажити PDF квиток"))]),_c('submit-button',{staticStyle:{"width":"316px","height":"48px !important","margin-top":"24px"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" На головну ")])],1)],1):_vm._e()],1)],1):_c('v-col',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("@/assets/img/errorIcon.png"),"width":_vm.$vuetify.breakpoint.smAndDown ? '150px' : '240px',"height":_vm.$vuetify.breakpoint.smAndDown ? '150px' : '240px',"alt":"success payment"}}),_c('h3',{staticStyle:{"color":"#ed4337","text-align":"center","font-family":"MacPaw Fixel Display","font-style":"normal","font-weight":"500","line-height":"normal","margin-top":"32px"},style:(_vm.$vuetify.breakpoint.smAndDown
              ? ' font-size: 32px;'
              : ' font-size: 24px;')},[_vm._v(" Помилка оплати ")]),_c('p',{staticClass:"text",staticStyle:{"margin-top":"12px"},style:(!_vm.$vuetify.breakpoint.smAndDown ? 'font-size: 16px' : '')},[_vm._v(" Схоже, щось пішло не так. Квиток не був оплачений або виникла помилка. ")]),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('submit-button',{staticStyle:{"width":"300px","height":"48px !important","font-weight":"500","font-size":"18px"},attrs:{"color":"#144FA9"},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v(" На головну ")])],1)],1)],1)],1),(_vm.order !== null)?_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"pdf-quality":2,"manual-pagination":false,"filename":`Квиток ${_vm.order?.departure?.translations?.name} - ${
      _vm.order?.destination?.translations?.name
    } ${new Date(_vm.order?.schedule?.departed_at).toLocaleDateString('uk-UA', {
      month: 'short',
      day: 'numeric',
    })}`,"pdf-format":"a4","pdf-margin":100,"pdf-orientation":"portrait"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('pdf-ticket',{attrs:{"ticket":_vm.order,"isPdf":_vm.isPdf,"text":_vm.text,"isFront":true,"indexTicket":_vm.indexTicket}})],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }